import React from "react"
import {Link} from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import playButton from "../../assets/images/icons/play.svg"
import videoOneThumb from "../../assets/videos/gallery/videos/hope-probe-in-japan.png"
import videoTwoThumb from "../../assets/videos/gallery/videos/emm-journey.png"
import videoThreeThumb from "../../assets/videos/gallery/videos/emm-timeline.png"
import videoFourThumb from "../../assets/videos/gallery/videos/mars-in-culture.png"
import videoFiveThumb from "../../assets/videos/gallery/videos/mars-trivia-ar.png"
import videoSixThumb from "../../assets/videos/gallery/videos/significant-mission-ar.png"
import videoSevenThumb from "../../assets/videos/gallery/videos/jump-ar.png"
import videoEightThumb from "../../assets/videos/gallery/videos/emm-chairmen-interview.png"
import videoNineThumb from "../../assets/videos/gallery/videos/science-instruments.png"

const videosPage = () => (
  <Layout lang="ar">
    <SEO title="Videos" lang="ar" />
    <main>
        <div className="standard-container">
          <h2 className="relative uk-text-center mt6 mb5">
          الفيديوهات
            <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
          </h2>
          <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-4@s uk-text-center" data-uk-grid data-uk-lightbox>
          <a href="https://emm-f548.kxcdn.com/hope-probe-in-japan.mp4">
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={videoOneThumb} width="100%" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <img src={playButton} className="play-button" style={{height: "50px"}} alt=""/>
                  </div>
              </div>
              </div>
            </a>
            <a href="https://emm-f548.kxcdn.com/emm-journey.mp4">
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={videoTwoThumb} width="100%" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <img src={playButton} className="play-button" style={{height: "50px"}} alt=""/>
                  </div>
              </div>
              </div>
            </a>
            <a href="https://emm-f548.kxcdn.com/mars-in-culture.mp4">
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={videoFourThumb} width="100%" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <img src={playButton} className="play-button" style={{height: "50px"}} alt=""/>
                  </div>
              </div>
              </div>
            </a>
            <a href="https://emm-f548.kxcdn.com/mars-trivia-ar.mp4">
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={videoFiveThumb} width="100%" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <img src={playButton} className="play-button" style={{height: "50px"}} alt=""/>
                  </div>
              </div>
              </div>
            </a>
            <a href="https://emm-f548.kxcdn.com/significant-mission-ar.mp4">
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={videoSixThumb} width="100%" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <img src={playButton} className="play-button" style={{height: "50px"}} alt=""/>
                  </div>
              </div>
              </div>
            </a>
            <a href="https://emm-f548.kxcdn.com/emm-timeline.mp4">
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={videoThreeThumb} width="100%" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <img src={playButton} className="play-button" style={{height: "50px"}} alt=""/>
                  </div>
              </div>
              </div>
            </a>
            <a href="https://emm-f548.kxcdn.com/jump-ar.mp4">
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={videoSevenThumb} width="100%" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <img src={playButton} className="play-button" style={{height: "50px"}} alt=""/>
                  </div>
              </div>
              </div>
            </a>
            <a href="https://emm-f548.kxcdn.com/emm-chairmen-interview.mp4">
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={videoEightThumb} width="100%" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <img src={playButton} className="play-button" style={{height: "50px"}} alt=""/>
                  </div>
              </div>
              </div>
            </a>
            <a href="https://emm-f548.kxcdn.com/science-instruments.mp4">
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={videoNineThumb} width="100%" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <img src={playButton} className="play-button" style={{height: "50px"}} alt=""/>
                  </div>
              </div>
              </div>
            </a>
          </div>
          <div className="mv5">
          </div>   
        </div>
    </main>
  </Layout>
)

export default videosPage
